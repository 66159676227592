import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokens } from "../../api";

const initialState = {
    tokens: {},
    page: 0,
    isLastPage: false,
    limit: 10,
    isLoading: false,
    isError: false,
    errorMessage: '',
};

export const fetchTokens = createAsyncThunk(
    'TOKENS_STATE/fetchTokens',
    async (payload, { getState }) => {
        try {
            const state = getState()
            if (state.tokens.tokens[payload]) {
                return { tokens: state.tokens.tokens[payload].tokens, isLastPage: Boolean(state.tokens.tokens[payload].tokens.length < state.tokens.limit), page: payload };
            }
            const data = await getTokens(payload, state.tokens.limit);
            const isLastPage = Boolean(data.tokens.length < state.tokens.limit)
            return { tokens: data.tokens, isLastPage, page: payload };
        } catch (err) {
            throw err
        }
    }
);

const TokenSlice = createSlice({
    name: "tokens",
    initialState,
    extraReducers: {
        [fetchTokens.pending.toString()]: (state) => {
            state.isLoading = true
        },
        [fetchTokens.fulfilled.toString()]: (state, { payload }) => {
            state.isLoading = false
            state.isLastPage = payload.isLastPage
            if (payload.tokens.length > 0) {
                state.page = payload.page
                state.tokens[payload.page] = {
                    tokens: payload.tokens
                }
            }
        },
        [fetchTokens.rejected.toString()]: (state, { error }) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = error.message
        },
    },
    reducers: {},
});

export const { } = TokenSlice.actions;

export default TokenSlice.reducer;