export function setJwtInCookie(cookieName, token) {
    const userData = `${token}`;
    // Encode the user data before storing it in the cookie
    const encodedUserData = btoa(userData);
    document.cookie = `${cookieName}=${encodedUserData}; path=/; expires=7`;
}

export function getJwtFromCookie(cookieName) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === cookieName) {
            // Decode the encoded user data
            const token = atob(value);
            return { token };
        }
    }
    return null;
}

export function clearJwtCookie(cookieName) {
    document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
}