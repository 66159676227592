import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebarShow: true,
    sidebarUnfoldable: false
}

const SideBarSlice = createSlice({
    name: "sideBar",
    initialState,
    extraReducers: {},
    reducers: {
        setSideBarShow: (state, action) => {
            state.sidebarShow = action.payload
            state.sidebarUnfoldable = !action.payload
        },

        setSidebarUnfoldable: (state, action) => {
            state.sidebarUnfoldable = action.payload
            state.sidebarShow = !action.payload
        },
    },
});  

export const { setSideBarShow, setSidebarUnfoldable } = SideBarSlice.actions;

export default SideBarSlice.reducer;
