import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { logInAdmin } from "../../api";
import { setJwtInCookie } from "src/views/pages/login/utils";

const initialState = {
    username: "",
    token: "",
    cookieName: "loginJWT",
    isLocked: false,
    isLoading: false,
    isError: false,
    errorMessage: '',
};

export const signInAdmin = createAsyncThunk(
    'LOGIN_STATE/signInAdmin',
    async (payload, { getState }) => {
        try {
            const state = getState()
            if (state.login.token) {
                return { username: state.login.username, token: state.login.token };
            }
            const data = await logInAdmin(payload.username, payload.password)
            if (data.status === 200) {
                return { username: payload.username, token: data.token, error: null };
            } else {
                return { username: null, token: null, error: data.error }
            }
        } catch (err) {
            throw err
        }
    }
);

const LoginSlice = createSlice({
    name: "login",
    initialState,
    extraReducers: {
        [signInAdmin.pending.toString()]: (state) => {
            state.isLoading = true
        },
        [signInAdmin.fulfilled.toString()]: (state, { payload }) => {
            state.isLoading = false
            if (payload.username && payload.token) {
                state.isLocked = false
                state.username = payload.username
                state.token = payload.token
                setJwtInCookie(initialState.cookieName, payload.token)
            }
            state.isError = payload.error
        },
        [signInAdmin.rejected.toString()]: (state, { error }) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = error.message
        },
    },
    reducers: {
        setUserToken: (state, action) => {
            state.username = action.payload.username
            state.token = action.payload.token
        },
        setIsLocked: (state, action) => {
            state.isLocked = true
            state.token = null
            state.username = null
        },
    },
});

export const { setUserToken, setIsLocked } = LoginSlice.actions;

export default LoginSlice.reducer;