import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAccounts } from "../../api";

const initialState = {
    accounts: {},
    page: 0,
    isLastPage: false,
    limit: 10,
    isLoading: false,
    isError: false,
    errorMessage: '',
};

export const fetchAccounts = createAsyncThunk(
    'ACCOUNTS_STATE/fetchAccounts',
    async (payload, { getState }) => {
        try {
            const state = getState()
            if (state.accounts.accounts[payload]) {
                return { accounts: state.accounts.accounts[payload].accounts, isLastPage: Boolean(state.accounts.accounts[payload].accounts.length < state.accounts.limit), page: payload };
            }
            const data = await getAccounts(payload, state.accounts.limit)
            const isLastPage = Boolean(data.accounts.length < state.accounts.limit)
            return { accounts: data.accounts, isLastPage, page: payload };
        } catch (err) {
            throw err
        }
    }
);

const AccountSlice = createSlice({
    name: "accounts",
    initialState,
    extraReducers: {
        [fetchAccounts.pending.toString()]: (state) => {
            state.isLoading = true
        },
        [fetchAccounts.fulfilled.toString()]: (state, { payload }) => {
            state.isLoading = false
            state.isLastPage = payload.isLastPage
            if (payload.accounts.length > 0) {
                state.page = payload.page
                state.accounts[payload.page] = {
                    accounts: payload.accounts
                }
            }
        },
        [fetchAccounts.rejected.toString()]: (state, { error }) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = error.message
        },
    },
    reducers: {},
});

export const { } = AccountSlice.actions;

export default AccountSlice.reducer;