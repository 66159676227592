import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBanners, storeBanner, deleteBanner } from "../../api";

const initialState = {
    section: "",
    banners: [],
    isLoading: false,
    isError: false,
    errorMessage: '',
};

export const fetchBanners = createAsyncThunk(
    'BANNERS_STATE/fetchBanners',
    async (payload, { getState }) => {
        try {
            const state = getState()
            if (state.banners.banners.length === 0) {
                const data = await getBanners();
                return { banners: data.banners };
            }
        } catch (err) {
            throw err
        }
    }
);

export const saveBanner = createAsyncThunk(
    'BANNERS_STATE/saveBanner',
    async (payload, { getState }) => {
        try {
            const result = await storeBanner(payload)
            if (result.status === 200) {
                return { imgPath: result.imgPath };
            }
        } catch (err) {
            throw err
        }
    }
);

export const removeBanner = createAsyncThunk(
    'BANNERS_STATE/deleteBanner',
    async (payload, { getState }) => {
        try {
            const result = await deleteBanner(payload.imgPath, payload.section)
            if (result.status === 200) {
                return { imgPath: payload.imgPath, section: payload.section };
            }
        } catch (err) {
            throw err
        }
    }
);

const BannerSlice = createSlice({
    name: "banners",
    initialState,
    extraReducers: {
        [fetchBanners.pending.toString()]: (state) => {
            state.isLoading = true
        },
        [fetchBanners.fulfilled.toString()]: (state, { payload }) => {
            state.isLoading = false
            state.banners = payload.banners
        },
        [fetchBanners.rejected.toString()]: (state, { error }) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = error.message
        },

        [saveBanner.pending.toString()]: (state) => {
            state.isLoading = true
        },
        [saveBanner.fulfilled.toString()]: (state, { payload }) => {
            state.isLoading = false;
            // Find the index of the object with a matching 'section'
            const index = state.banners.findIndex((banner) => banner.section === state.section);
            if (index !== -1) {
                // If an object with the same 'section' exists, update its 'img_path'
                state.banners[index].img_path = payload.imgPath;
            } else {
                // If no object with the same 'section' exists, add a new object to the array
                state.banners.push({ img_path: payload.imgPath, section: state.section });
            }
        },
        [saveBanner.rejected.toString()]: (state, { error }) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = error.message
        },

        [removeBanner.pending.toString()]: (state) => {
            state.isLoading = true
        },
        [removeBanner.fulfilled.toString()]: (state, { payload }) => {
            state.isLoading = false
            state.banners = state.banners.filter(item => (item.img_path !== payload.imgPath && item.section !== payload.section));
        },
        [removeBanner.rejected.toString()]: (state, { error }) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = error.message
        },
    },
    reducers: {
        setSection: (state, action) => {
            state.section = action.payload
        },
    },
});

export const { setSection } = BannerSlice.actions;

export default BannerSlice.reducer;