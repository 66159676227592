import axios from './axiosInstance'
import { CONFIG } from 'src/config';

export const fetchPublicTagRequests = async () => {
    try {
        const result = await axios.get(`${CONFIG.BACKEND_URL}getPublicTagsRequests`)
        // console.log("api/fetchPublicTagRequests: ", result.data)
        return result.data
    } catch (err) {
        console.log(err)
    }
}

export const ApprovePublicTagRequests = async (params) => {
    try {
        const result = await axios.get(`${CONFIG.BACKEND_URL}adminApprovePublicTagsRequest`, { params })
        // console.log("api/ApprovePublicTagRequests: ", result.data)
        return result?.data
    } catch (err) {
        console.log(err)
    }
}

export const DeletePublicTagRequests = async (params) => {
    try {
        const result = await axios.delete(`${CONFIG.BACKEND_URL}deletePublicTagsRequests`, {   params  })
        // console.log("api/DeletePublicTagRequests: ", result.data)
        return result?.data
    } catch (err) {
        console.log(err)
    }
}

export const getAccounts = async (page, limit) => {
    try {
        const result = await axios.get(`${CONFIG.BACKEND_URL}getAccounts`, { params: { page, limit } })
        // console.log("api/getAccounts: ", result.data)
        return result.data
    } catch (err) {
        console.log(err)
    }
}

export const getTokens = async (page, limit) => {
    try {
        const result = await axios.get(`${CONFIG.BACKEND_URL}getTokens`, { params: { page, limit } })
        // console.log("api/getTokens: ", result.data)
        return result.data
    } catch (err) {
        console.log(err)
    }
}

export const getBanners = async () => {
    const result = await axios.get(`${CONFIG.BACKEND_URL}getBanners`)
    // console.log("api/getBanners: ", result.data)
    return result.data
}

export const storeBanner = async (formData) => {
    const result = await axios.post(`${CONFIG.BACKEND_URL}uploadBanner`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Important for file upload
        },
    });
    // console.log("api/storeBanner: ", result.data)
    return result.data
}

export const deleteBanner = async (imgPath, section) => {
    const result = await axios.delete(`${CONFIG.BACKEND_URL}deleteBanner`, { params: { imgPath, section } })
    // console.log("api/deleteBanner: ", result)
    return result
}

export const logInAdmin = async (username, password) => {
    const result = await axios.post(`${CONFIG.BACKEND_URL}adminLogin`, { username, password })
    // console.log("api/logInAdmin: ", result)
    return result.data
}

export const checkJwtValidity = async (token) => {
    const result = await axios.post(`${CONFIG.BACKEND_URL}checkJwtValidity`, { token })
    // console.log("api/checkJwtValidity: ", result)
    return result.data
}

export const adminBlockPublisherRequest = async (publisherAddress) => {
    const result = await axios.get(`${CONFIG.BACKEND_URL}blockPublisher`, { params: { publisherAddress } })
    // console.log("api/blockPublisher: ", result.data)
    return result.data
}

export const adminUnBlockPublisherRequest = async (publisherAddress) => {
    const result = await axios.get(`${CONFIG.BACKEND_URL}unBlockPublisher`, { params: { publisherAddress } })
    // console.log("api/unBlockPublisher: ", result.data)
    return result.data
}

export const adminDeletePublisherRequest = async (publisherAddress) => {
    const result = await axios.get(`${CONFIG.BACKEND_URL}removePublisher`, { params: { publisherAddress } })
    // console.log("api/removePublisher: ", result.data)
    return result.data
}

export const adminBlockAuthorRequest = async (authorAddress) => {
    const result = await axios.get(`${CONFIG.BACKEND_URL}blockAuthor`, { params: { authorAddress } })
    // console.log("api/blockAuthor: ", result.data)
    return result.data
}

export const adminUnBlockAuthorRequest = async (authorAddress) => {
    const result = await axios.get(`${CONFIG.BACKEND_URL}unBlockAuthor`, { params: { authorAddress } })
    // console.log("api/unblockAuthor: ", result.data)
    return result.data
}

export const adminDeleteAuthorRequest = async (authorAddress) => {
    const result = await axios.get(`${CONFIG.BACKEND_URL}removeAuthor`, { params: { authorAddress } })
    // console.log("api/removeAuthor: ", result.data)
    return result.data
}

export const register = async (username, password) => {
    const result = await axios.post(`${CONFIG.BACKEND_URL}register`, { username, password })
    // console.log("api/register: ", result)
    return result.data
}