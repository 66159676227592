import { configureStore } from "@reduxjs/toolkit";
import AccountsReducer from "./AccountSlice";
import TokensReducer from "./TokenSlice";
import SideBarReducer from "./SideBarSlice";
import BannersReducer from "./BannerSlice";
import LoginReducer from './LoginSlice';

const store = configureStore({
  reducer: {
    accounts: AccountsReducer,
    tokens: TokensReducer,
    sideBar: SideBarReducer,
    banners: BannersReducer,
    login: LoginReducer,
  },
});

export default store;